import Vue  from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Local data storage
// import localforage from 'localforage'
// const sessionStorage = localforage.createInstance({name: 'escaperoom'})

const timeText = function (t) {
  if (isNaN(t)) return '00:00';
  const m = Math.floor(t / 60);
  const s = t % 60;
  let mm: string, ss: string;
  mm = (m < 10) ? ('0' + m.toString()) : m.toString();
  ss = (s < 10) ? ('0' + s.toString()) : s.toString();
  return '' + mm + ':' + ss;
}

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, collection, query, getDocs, onSnapshot } from "firebase/firestore";
const firebaseApp = initializeApp({
  apiKey: "AIzaSyBeW5VF3DtDdGfGGCT0DCk0y02i6DgGOQk",
  authDomain: "cyber-escape-room.firebaseapp.com",
  projectId: "cyber-escape-room",
  storageBucket: "cyber-escape-room.appspot.com",
  messagingSenderId: "298747396686",
  appId: "1:298747396686:web:e771ff7383086d31f2f91f"
});
const db = getFirestore();

import defaulter from '@/modules/defaulter'

export default new Vuex.Store({
  state: {
    user: null,
    uid:  null,
    scenarioId: 'harmony',
    sessionId:  null,
    scenario: {
      name: 'Harmony',
      description: 'Use your skills to save the Harmony of the Seas from malicious hackers.'
    },
    session: {
      id: '',
      open: false,
      allowSelfStart: false,
      started: false
    },
    team: {
      id:      null,
      name:    '',
      members: [],
      ready:   0,
      requiresAssistance: 0,
      visitedAquaTheater: 0,
      visitedBoardwalk:   0,
      visitedCentralPark: 0,
      visitedPromenade:   0,
      // completedEntrance:     0,
      completedIntroduction: 0,
      completedIntroductionVideo: 0,
      completedAquaTheater:  0,
      completedAquaTheaterVideo:  0,
      completedBoardwalk:    0,
      completedBoardwalkVideo:    0,
      completedCentralPark:  0,
      completedCentralParkVideo:  0,
      completedPromenade:    0,
      completedPromenadeVideo:    0,
      completedTheater:      0,
      completedBridge:       0,
      lastLocation: '',
      hintsRemaining: 4,
      seenFindLaptopHint: 0,
      timeAquaTheater: 0,
      timeBoardwalk:   0,
      timeCentralPark: 0,
      timePromenade:   0,
      timeTheater:     0,
      timeBridge:      0,
      timePenalty:     0,
      timeTotal:       0,
    },
    timerLocal: {
      timeAquaTheater: 0,
      timeBoardwalk:   0,
      timeCentralPark: 0,
      timePromenade:   0,
      timeTheater:     0,
      timeBridge:      0,
      timePenalty:     0,
      timeTotal:       0,
    },
    timerVar: '',
    timerTime: 0,
    timerTimer: null,
    allTeams: {},
    unsubList: {},
    stageAnswers: {
      'FromName':    'FROM',
      'Links':       'SAFE',
      'Attachments': 'CAREFUL',
      'Emotions':    'TIME',
    },
    stageHints: {
      'FromName': [
        'The password is 4 letters long.',
        'The password starts with an "F"'
      ],
      'Links': [
        'The password is 4 letters long.',
        'The password starts with an "S"'
      ],
      'Attachments': [
        'The password is 7 letters long.',
        'The password starts with a "C"'
      ],
      'Emotions': [
        'The password is 4 letters long.',
        'The password starts with a "T"'
      ],
    },
    // Game Master Data
    sessions: {},
  },

  /////////////
  // Getters //
  /////////////
  getters: {
    user: state => state.user,
    uid: state => state.uid,
    scenarioId: state => state.scenarioId,
    scenario: state => state.scenario,
    sessionsCollectionPath: state => {
      if (state.scenarioId) {
        return 'scenarios/' + state.scenarioId + '/sessions/'
      }
      return null
    },
    sessionPath: state => {
      if (state.scenarioId && state.sessionId) {
        return 'scenarios/' + state.scenarioId + '/sessions/' + state.sessionId
      }
      return null
    },
    sessions: state => state.sessions,
    session: state => state.session,
    sessionIsOpen: state => {
      return (state.session && state.session.open)
    },
    teamsCollectionPath: (state, getters) => {
      if (state.uid && getters.sessionPath) {
        return getters.sessionPath + '/teams/'
      }
      return null
    },
    teamPath: (state, getters) => {
      if (state.uid && getters.sessionPath) {
        return getters.sessionPath + '/teams/' + state.uid
      }
      return null
    },
    team: state => state.team,
    teamName: state => state.team.name,
    seenFindLaptopHint: state => state.team.seenFindLaptopHint,
    stageTimes: state => state.timerLocal,
    timerText (state) {
      return timeText(state.timerLocal.timeTotal)
    },
    stagesComplete: state => {
      let count = 0
      if (state.team.ready) {
        if (state.team.completedAquaTheater) count++
        if (state.team.completedBoardwalk)   count++
        if (state.team.completedCentralPark) count++
        if (state.team.completedPromenade)   count++
        if (state.team.completedTheater)     count++
      }
      return count
    },
    finalStageComplete: state => {
      return (state.team.completedBridge) ? true : false
    },
    hintsRemaining: state => state.team.hintsRemaining,
    stageAnswers: state => state.stageAnswers,
    stageHints: state => state.stageHints,
    allTeams: state => state.allTeams,
    teamRankings: state => {
      const ranked = [];
      let team: any, stagesComplete: number, timeTotal: number;
      for (let t in state.allTeams) {
        team = state.allTeams[t];
        stagesComplete = team.completedAquaTheater
          + team.completedBoardwalk
          + team.completedCentralPark
          + team.completedPromenade
          + team.completedTheater;
        timeTotal = team.timeTotal || 0,
        ranked.push({
          id: t,
          name: team.name,
          stagesComplete: stagesComplete,
          isDone: team.completedTheater?true:false,
          timeTotal: timeTotal,
          timeText: timeText(timeTotal),
          score: 5000 + (stagesComplete*25000) - timeTotal
        })
      }
      ranked.sort((a,b) => {
        if (a.score == b.score) return 0;
        return (b.score > a.score) ? 1 : -1;
      })
      return ranked
    }
  },

  ///////////////
  // Mutations //
  ///////////////
  mutations: {
    sessionPath (state, payload) {
      state.scenarioId = payload.scenarioName.toLowerCase()
      state.sessionId  = state.scenarioId + '-' + payload.pin
      // sessionStorage.setItem('sessionId', payload.pin)
    },
    sessionPin (state, payload: string) {
      state.sessionId = state.scenarioId + '-' + payload
    },
    sessionId (state, payload: string) {
      state.sessionId = payload
    },
    sessions (state, payload: object) {
      state.sessions = payload
    },
    session (state, payload: object) {
      state.session = defaulter.merge(state.session, payload)
    },
    team (state, payload: object) {
      state.team = defaulter.merge(state.team, payload)
      state.timerLocal = defaulter.merge(state.timerLocal, {
        timeAquaTheater: state.team.timeAquaTheater,
        timeBoardwalk:   state.team.timeBoardwalk,
        timeCentralPark: state.team.timeCentralPark,
        timePromenade:   state.team.timePromenade,
        timeTheater:     state.team.timeTheater,
        timeBridge:      state.team.timeBridge,
        timePenalty:     state.team.timePenalty,
        timeTotal:       state.team.timeTotal,
      })
    },
    allTeams (state, payload: object) {
      state.allTeams = payload
    },
  },

  /////////////
  // Actions //
  /////////////
  actions: {

    // Initialize App //

    async initialize ({state}) {
      const auth = getAuth()
      // Keep Store User ID in sync with Firebase Auth
      onAuthStateChanged(auth, (user) => {
        if (user) {
          state.user = user
          state.uid  = user.uid
        } else {
          state.user = null
          state.uid  = null
        }
        if (user) console.log('uid', user.uid)
      })
      getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
    
        // The signed-in user info.
        const user = result.user;
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    },

    // Firebase Auth //

    async signInThroughProvider ({state, dispatch}, providerName: string) {
      console.log('store.signInThroughProvider', providerName)
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider)
      // signInWithPopup(auth, provider)
      //   .then((result) => {
      //     // This gives you a Google Access Token. You can use it to access the Google API.
      //     const credential = GoogleAuthProvider.credentialFromResult(result);
      //     const token = credential.accessToken;
      //     // The signed-in user info.
      //     const user = result.user;
      //     // ...
      //     console.log('logged in thru Google')
      //     console.log('  credential', credential)
      //     console.log('  token', token)
      //     console.log('  user', user)
      //   }).catch((error) => {
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.email;
      //     // The AuthCredential type that was used.
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      //   });
    }, // end of signInThroughProvider

    // Firebase Firestore Firefunctions //

    async fetchDocument ({commit, dispatch}, payload) {
      let document;
      try {
        document = await getDoc(doc(db, payload.path))
      } catch (error) {
        console.warn('Error fetching document', payload, error)
        return false
      }
      let docData = document.data()
      if (payload.hasOwnProperty('commitTo') && payload.commitTo) {
        commit(payload.commitTo, docData)
        dispatch('subscribeToDocument', payload)
      }
      return docData
    },

    subscribeToDocument ({state, commit}, payload) {
      const path: string = payload.path
      const commitTo: string = payload.commitTo
      if (state.unsubList.hasOwnProperty(commitTo)) {
        if (path === state.unsubList[commitTo].path && commitTo === state.unsubList[commitTo].commitTo) return
        state.unsubList[commitTo].unsub()
      }
      const unsub = onSnapshot(
        doc(db, path),
        (doc) => {
          commit(commitTo, doc.data())
        },
        (error) => {
          console.warn('Could not subscribe to document:', payload, error)
        });
      state.unsubList[commitTo] = (unsub) ? { path, commitTo, unsub } : null
    },

    async fetchCollection ({commit, dispatch}, payload) {
      const path: string = payload.path
      const q = query(collection(db, path));
      const querySnapshot = await getDocs(q);
      const collectionData = {}
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        collectionData[doc.id] = doc.data()
      })
      if (payload.hasOwnProperty('commitTo') && payload.commitTo) {
        commit(payload.commitTo, collectionData)
        dispatch('subscribeToDocument', payload)
      }
      return collectionData
    },

    subscribeToCollection ({state, commit}, payload) {
      const path: string = payload.path
      const commitTo: string = payload.commitTo
      if (state.unsubList.hasOwnProperty(commitTo)) {
        if (path === state.unsubList[commitTo].path && commitTo === state.unsubList[commitTo].commitTo) return
        state.unsubList[commitTo].unsub()
      }
      const unsub = onSnapshot(
        collection(db, path),
        (querySnapshot) => {
          const docs = {}
          querySnapshot.forEach(function(doc) {
            docs[doc.id] = doc.data();
          });
          commit(commitTo, docs)
        },
        (error) => {
          console.warn('Could not subscribe to collection:', payload, error)
        });
      state.unsubList[commitTo] = (unsub) ? { path, commitTo, unsub } : null
    },

    // Start or Continue Session //

    async initSession ({state, getters, commit, dispatch}, payload) {
      // If not already authenticated with firebase, do so now.
      if (!state.uid) {
        const auth = getAuth()
        const cred = await signInAnonymously(auth)
          .catch((error) => {
            console.warn('auth error', error.code, error.message)
            return false
          });
        if (!cred) return false
      }
      // Firestore Paths
      commit('sessionPath', payload)
      const sessionPath = getters.sessionPath
      const teamPath    = getters.teamPath
      // Check specified scenario session exists and is open for new teams.
      const session = await dispatch('fetchDocument', {
        path: sessionPath,
        commitTo: 'session'
      })
      if (!(session && session.open)) return false
      // Check if team already created.
      let team = await dispatch('fetchDocument', {
        path: teamPath,
        commitTo: 'team'
      })
      // // Create team, if not done already.
      // if (!team) {
      //   await setDoc(doc(db, teamPath), defaulter.merge(state.team, {id: state.uid}), { merge: true })
      //   .catch((error)=>{
      //     console.warn('error creating team', error)
      //   })
      //   team = await dispatch('fetchDocument', {
      //     path: teamPath,
      //     commitTo: 'team'
      //   })
      //   if (!team) {
      //     return false;
      //   }
      // }
      dispatch('subscribeToCollection', {
        path:  sessionPath+'/teams',
        commitTo: 'allTeams'
      })
      return true
    },

    async continueSession ({state, getters, commit, dispatch}) {
      const sessionPin = await sessionStorage.getItem('sessionId')
      if (!(state.uid && state.scenario.name)) return false
      commit('sessionPath', {
        scenarioName: state.scenario.name,
        pin: sessionPin
      })
      await dispatch('fetchDocument', {
        path: getters.sessionPath,
        commitTo: 'session'
      })
      const team = await dispatch('fetchDocument', {
        path: getters.teamPath,
      })
      if (team) {
        // Merge with latest team data model
        await setDoc(doc(db, getters.teamPath), defaulter.merge(state.team, team), { merge: true })
        .catch((error)=>{
          console.warn('error updating team', error)
        })
        // Subscribe to team document
        dispatch('subscribeToDocument', {
          path: getters.teamPath,
          commitTo: 'team'
        })
        // Subscribe to all team documents
        dispatch('subscribeToCollection', {
          path:  getters.teamsCollectionPath,
          commitTo: 'allTeams'
        })
      }
      return true
    },

    // Update Game Progress //

    async updateMyTeam ({state, getters, dispatch}, payload) {
      // Check if team already created.
      const teamPath = getters.teamPath
      if (!state.team.id) {
        await dispatch('fetchDocument', {
          path: teamPath,
          commitTo: 'team'
        })
      }
      // Create team, if not done already.
      if (!state.team.id) {
        console.log('Creating team with ID', state.uid)
        await setDoc(doc(db, teamPath), defaulter.merge(state.team, {id: state.uid}), { merge: true })
        .catch((error)=>{
          console.warn('error creating team', error)
        })
        await dispatch('fetchDocument', {
          path: teamPath,
          commitTo: 'team'
        })
      }
      // Still no team? Fail.
      if (!state.team.id) {
        return false;
      }

      if (!(teamPath)) return null
      payload = defaulter.merge(state.timerLocal, payload)
      await setDoc(doc(db, teamPath), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating team', error)
        return false
      })
      return true
    },

    timerStart ({state, getters, dispatch}, payload: string) {
      if (state.timerTimer) {
        dispatch('timerStop')
      }
      state.timerVar = 'time' + payload;
      console.log('timerStart', state.timerVar, state.timerLocal[state.timerVar], state.timerLocal.timeTotal)
      state.timerTimer = window.setInterval(()=>{
        if (state.timerLocal[state.timerVar] >= 3600) {
          dispatch('timerStop')
          return
        }
        Vue.set(state.timerLocal, state.timerVar, state.timerLocal[state.timerVar]+1)
        let timeTotal = state.timerLocal.timeAquaTheater
          + state.timerLocal.timeBoardwalk
          + state.timerLocal.timeCentralPark
          + state.timerLocal.timePromenade
          + state.timerLocal.timeTheater
          + state.timerLocal.timeBridge
          + state.timerLocal.timePenalty;
        Vue.set(state.timerLocal, 'timeTotal', timeTotal)
        if (timeTotal%60 === 0) {
          dispatch('updateMyTeam', state.timerLocal)
        }
      }, 1000)
    },
    timerStop ({state, dispatch}) {
      if (state.timerTimer) {
        window.clearInterval(state.timerTimer)
        state.timerTimer = null
      }
      dispatch('updateMyTeam', state.timerLocal)
    },

    async loseHint ({state, dispatch}) {
      if (state.team.hintsRemaining > 0) {
        return await dispatch('updateMyTeam', {hintsRemaining: state.team.hintsRemaining-1})
      }
      state.timerLocal.timePenalty += 30
      dispatch('updateMyTeam', state.timerLocal)
    },
    async refreshHints ({dispatch}) {
      return await dispatch('updateMyTeam', {hintsRemaining: 4})
    },

    async completeStage ({dispatch}, stage) {
      const prop = 'completed' + stage
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async completeStageVideo ({dispatch}, stage) {
      const prop = 'completed' + stage + 'Video'
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async visitStage ({dispatch}, stage) {
      const prop = 'visited' + stage
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async requestAssistance ({dispatch}) {
      return await dispatch('updateMyTeam', {'requiresAssistance': 1})
    },

    ///////////////////////////
    // Game Master Functions //
    ///////////////////////////

    async listSessions ({getters, dispatch}) {
      return await dispatch('fetchCollection', {path: getters.sessionsCollectionPath})
    },

    async subscribeToSessions ({getters, dispatch}) {
      return await dispatch('subscribeToCollection', {
        path: getters.sessionsCollectionPath,
        commitTo: 'sessions'
      })
    },

    async createSession ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.sessionsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error creating document', error)
        return false
      })
      return true
    },

    async updateSession ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.sessionsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating document', error)
        return false
      })
      return true
    },

    async subscribeToTeams ({getters, dispatch}) {
      return await dispatch('subscribeToCollection', {
        path: getters.teamsCollectionPath,
        commitTo: 'allTeams'
      })
    },

    async updateTeam ({getters}, payload) {
      if (!(getters.teamsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.teamsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating document', error)
        return false
      })
      return true
    },

  } // end of actions
})
